<template>
  <div class="rankinglist-style">
    <div class="box1">{{$t('text.t126')}}</div>
    <div class="box2">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="goAhead(`/collectionshop?id=${item.id}`)"
        >
          <div class="item-b1">
            <img
              :src="$http.imgurl + item.selectedimage"
              alt=""
              class="item-b1-img"
            />
          </div>
          <div class="item-b2 end-center">
            <div class="ib2-box1">
              <div class="ib2-box1-t1">{{ item.collectionname }}</div>
              <div class="start-center">
                <img src="@/assets/img/img20.png" alt="" class="ib2-box1-i1" />
                <div class="ib2-box1-t2">{{ item.number }}</div>
              </div>
            </div>
            <div class="item-b2-pos">
              <img
                :src="$http.imgurl + item.collectionimage"
                alt=""
                class="item-b2-pos-i1"
              />
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {},
  mounted() {
    this.$bus.$on("upRankinglist", (e) => {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == e) {
          console.log(this.list[i]);
          if(this.list[i].is_collect == 2){
            this.list[i].is_collect = 1
            this.list[i].number++
          } else{
            this.list[i].is_collect = 2
            this.list[i].number--
          }
        }
      }
    });
  },
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
      };
      this.$http.get("/collections/collectList", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    const whitelist = ["Collectionshop"];
    console.log(whitelist.includes(to.name));
    if (whitelist.includes(to.name)) {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
};
</script>

<style lang="less">
.rankinglist-style {
  .box1 {
    padding: 30px 0 20px;
    text-align: center;
    border-bottom: 1px solid #e1e1e1;
    font-size: 24px;
    font-weight: 800;
    color: #323232;
    margin-bottom: 10px;
  }
  .box2 {
    width: 335px;
    margin: auto;
    .item {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 16px rgba(50, 50, 50, 0.1);
      margin-bottom: 16px;
      .item-b1 {
        width: 100%;
        height: 190px;
        overflow: hidden;
        .item-b1-img {
          width: 100%;
          height: auto;
        }
      }
      .item-b2 {
        position: relative;
        padding: 13px;
        .ib2-box1 {
          width: calc(100% - 97px);
          .ib2-box1-t1 {
            font-size: 23px;
            font-weight: bold;
            color: #323232;
            margin-bottom: 10px;
          }
          .ib2-box1-i1 {
            width: 15px;
            height: 15px;
            margin-right: 8px;
          }
          .ib2-box1-t2 {
            font-size: 15px;
            font-weight: bold;
            color: #323232;
          }
        }
        .item-b2-pos {
          width: 87px;
          height: 87px;
          border: 3px solid #ffffff;
          border-radius: 4px;
          position: absolute;
          left: 13px;
          top: -44px;
          box-shadow: 0 0 6px rgba(36, 36, 36, 0.3);
          box-sizing: border-box;
          background-color: #fff;
          .item-b2-pos-i1 {
            border-radius: 4px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>